.background {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    animation: gradient 15s ease infinite;
    min-height: 100vh;
    min-width: 100v;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.hometitle {
    font-family: 'Harmony', harmony;
    font-size: 52px;
    letter-spacing: 12px;
    text-align: center;
    margin-bottom: 5px;
    color: #f3f1f1;
    text-align: center;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 7rem;
    padding-bottom: 0rem;
}
@media only screen and (max-width: 767px) {
    .hometitle{
        font-size: 45px;
        margin-left: 1rem;
        margin-right: 1rem;
        padding-top: 3rem;
    }
}
@media only screen and (max-width: 667px) {
    .hometitle{
        margin-left: 1rem;
        margin-right: 1rem;
        padding-top: 3rem;
        font-size: 39px;
    }
}
.hometitle::before {
    content: "";
    display: inline-block;
    width: 200px; 
    height: auto;
    background-image: url('https://c.tenor.com/Ihbu3iBTjGcAAAAM/better-call-saul-loop.gif');
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px; 
}

.hometitle::after {
    content: "";
    display: inline-block;
    width: 200px; 
    height: auto;
    background-image: url('https://c.tenor.com/Ihbu3iBTjGcAAAAM/better-call-saul-loop.gif');
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px; 
}




.safetytitle {
    font-size: 1;
    letter-spacing: 7px;
    text-align: center;
    margin-bottom: 20px;
    color: #f3f1f1;
    text-align: center;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
}
.safetytitle::before{
    content: "";
    display: inline-block;
    width: 200px; 
    height: auto;
    background-image: url('https://c.tenor.com/Ikt7sU8aaJMAAAAM/thrust-dance.gif');
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px; 
}

.safetytitle::after{
    content: "";
    display: inline-block;
    width: 200px; 
    height: auto;
    background-image: url('https://c.tenor.com/Ikt7sU8aaJMAAAAM/thrust-dance.gif');
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px; 
}
@media only screen and (max-width: 767px) {
    .safetytitle{
        font-size: 45px;
        margin-left: 1rem;
        margin-right: 1rem;
        padding-top: 1rem;
    }
}
@media only screen and (max-width: 667px) {
    .safetytitle{
        margin-left: 1rem;
        margin-right: 1rem;
        padding-top: 1rem;
        font-size: 39px;
    }
}
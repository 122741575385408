body, html {
  margin: 0;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Harmony', 'MagnumSans', 'Westmount';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Harmony';
  src: url('./fonts/Harmony.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'MagnumSans';
  src: url('./fonts/MagnumSans.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: 'Westmount';
  src: url('./fonts/Westmount.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
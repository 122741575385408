#topnav {
    width: 100%;
 
    position: absolute;
    top: 0;
    left: 0;
 
    background: linear-gradient(-183deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    animation: gradient 15s ease infinite;
 
    font-family: 'Segoe UI';
    font-size: 15px;
 }
 @media only screen and (max-width: 767px) {
    .topnav{
      font-size: 24;
    }
  }
  @keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
 
 .nav-link {
    display: inline-block;
    width: fit-content;
    height: 55px;
    padding-left: 20px;
 
    color: White;
 
    text-align: center;
    line-height: 55px;
 
    text-decoration: none;
    transition: transform 0.2s ease-in-out;
 
    &:hover {
      transform: scale(1.2);
      font-style: bold;
    }
  
}
    
 
